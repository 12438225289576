<template>
<div class="row">
  <div class="col-6 offset-3">
    <h2 class="text-center m-auto">{{ $t('views.constants.registrationComplete') }}</h2>
  </div>
</div>
</template>

<script>
export default {};
</script>
